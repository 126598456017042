/* eslint-disable vue/max-len */
/* eslint-disable max-len */
export default {
  predictionStep1Text: 'Прогноз не оставлен',
  predictionStep2Text: 'Прогноз не сохранен',
  predictionStep3Text: 'Прогноз успешно записан',
  predictionStep4Text: 'Ты хочешь поменять прогноз?',
  prizes: 'Призы',
  createTeamButtonText: 'Создай команду с друзьями',
  accountNewsletter: 'Подписаться на рассылку в Reddy',
  invalidReddyCode: 'Код введен некорректно. Проверьте код в Reddy и введите повторно.',
  groupsCardButton: 'Команды',
  groupsCardButtonActive: 'Свернуть',
  groupsCardScoresLabel: 'Всего баллов',
  topGoalscorer: 'Лучший бомбардир',
  topGoalkeeper: 'Лучший вратарь',
  top: 'Лучший',
  goalscorer: 'бомбардир',
  goalkeeper: 'вратарь',
  chooseFavorite: 'Выбери фаворита!',
  notChooseFavorite: 'Фаворит не выбран',
  playerSearchPlaceholder: 'Имя и фамилия футболиста...',
  userSearchPlaceholder: 'Имя или фамилия участника...',
  dateAndTime: 'Дата и время',
  teams: 'Команды',
  score: 'Счет',
  yourResult: 'Твой результат',
  tournamentsFeedErrorText: 'Не удалось загрузить список турниров',
  tournamentsFeedEmptyText: 'Нет доступных турниров',
  tournamentErrorText: 'Не удалось загрузить данные о турнире',
  tournamentsTypeFootball: 'Футбол',
  tournamentsTypeUFC: 'UFC',
  tournamentsTabText1: 'Все',
  tournamentsTabText2: 'Текущие',
  tournamentsTabText3: 'Прошедшие',
  playerTableEmptyText: 'Игрок не найден, попробуйте изменить параметры поиска',
  ratingTablePosition: 'Место',
  ratingTableUser: 'Участник',
  ratingTableTeam: 'Команда',
  ratingTablePoints: 'Баллы',
  ratingTableCoefSum: 'Сумма кэфов',
  ratingTableFilterSelect: 'Общий рейтинг',
  createUserTeamFormTitle: 'Твоя новая команда',
  createUserTeamInputPlaceholder: 'Введите название команды',
  createUserTeamFormSubmit: 'К победе!',
  shareInviteText: 'Отправь ссылку-приглашение своим друзьям!',
  confirmDeleteTeamText: 'Удалить команду?',
  confirmExitTeamText: 'Хотите выйти из команды?',
  alreadyInTeamText: 'Ты уже состоишь в этой команде',
  joinErrorTeamText: 'Ошибка вступления в команду',
  inviteToTeamText: 'Хочешь вступить в команду?',
  teamsLimitText: 'У тебя есть возможность создать не более трех команд с друзьями.',
  teamNameTaken: 'Такое название команды уже существует, выбери другое.',
  teamsInvalidName:
    'Используйте следующие символы: цифры, латинские и кириллические буквы, дефис и нижнее подчеркивание',
  teamPredictionTitle: 'Выбери 1 команду',
  'Команда выбрана': 'Команда выбрана',
  waitingResults: 'Ожидаем результатов',
  results: 'Результаты',
  teamsInvalidLengthName: 'Превышена длина названия. Напишите название до 30 символов',
  ctaRandom: 'Да поможет тебе великий рандом!',
  successPredictionText: 'Твой прогноз успешно записан!',
  scoreLimitErrorText: 'Масимальное количество голов на команду - 20',
  groupsTableCol1: 'Твой прогноз',
  groupsTableCol2: 'Кто прошел дальше',
  groupsTableCol3: 'Результаты',
  timerDayLabel: 'дн.',
  timerHoursLabel: 'час.',
  timerMinutesLabel: 'мин.',
  timerSecondsLabel: 'сек.',
  teamCounter: 'команд',
  teamCounterFormat: 'Выбрано {current}/{max} {teamCounter}',
  myTeam: 'Моя команда ({team})',
  friendsTeam: 'Команда друзей',
  allGroups: 'Все группы',
  allTeams: 'Все команды',
  faqTitle: 'Другой вопрос',
  faqContentTitle: 'Не нашел ответ на свой вопрос?',
  faqContentBody: 'Напиши его в Reddy нашей поддержке Sage Sports',
  footer: 'Обсуждайте прогнозы в нашей группе Reddy',
  hallOfFlameInDevelopment: 'Зал славы станет доступен позднее...',
  limitFavoriteRatings: 'Закрепить более 5 пользователей нельзя',
  loginFormTitle: 'Введите номер аккаунта в Reddy',
  otpFormTitle: 'Введите проверочный код, отправленный в чат Reddy',
  reddyIdNotFound: 'Номера не существует. Проверьте номер Reddy и введите повторно.',
  winnerGroupText1:
    '-  Первое место уже твое! Но кто ты без него?\n-  Гений, математик, аналитик, победитель по жизни!',
  winnerGroupText2: 'Так держать! ЛЧ - твоя стихия! Возвращайся и затащи новый турик, как "Байер" Бундеслигу!',
  winnerGroupText3: 'Да, ты в ТОП-3!\nНабирайся сил и продолжай в том же духе!',
  winnerGroupTextThunderstorm: 'Про таких, как ты, говорят: “Он вынес контору”.\n Пока прет, советуем бежать в казино!',
  // eslint-disable-next-line max-len,vue/max-len
  winnerGroupTextLoser:
    'Если тебя это утешит, то ты "первый" с конца!\nА если нет, то знай, букмекерские\nконторы тебя любят и ждут.',
  winnerText1: 'Наш намбер Ван!\nНе вратарь, а ТОП-1!',
  winnerText2: 'В шаге от победы!\nНо… карточка, пенальти и серебро на шее!',
  winnerText3: 'Атлетико Мадрид №2!\nВыше только Гранды!',
  winnerText4: '4 место?\nДа, как дважды два!',
  winnerText5: 'Местный отличник!\nЗнает ЛЧ, как свои пять пальцев!',
  winnerText6: 'Человек-шестое чувство:\nне аналитика, а интуиция!',
  winnerText7: 'Настоящий Криш!\nВ Аравии подделка, а у нас - неповторимый оригинал!',
  winnerText8: 'Золотая середина!\nЗанял мид и доволен!',
  winnerText9: 'Все твои прогнозы зашли… точно в девяточку!\nДаже паучок не выжил!',
  winnerText10: 'Не “ложная” десятка!\nНа этой позиции тебя не заменить!',
  winnerText11: 'Недосягаемый и недооцененный - 11 номер!\nБез 5-ти минут Дрогба!',
  winnerText12: 'Герой-скамейки!\nГотовься первым выйти на замену!',
  winnerText13: 'Разрушитель проклятий!\nКошмар для цифры 13!',
  winnerText14: 'Ты - как Кройф и Анри:\nлегенда номер 14!',
  winnerText15: 'На тоненького!\nПоздравляем, ты успел в последний автобус!',
  winnerNomination1: '1-ое место',
  winnerNomination2: '2-ое место',
  winnerNomination3: '3-е место',
  winnerNomination4: '4-ое место',
  winnerNomination5: '5-ое место',
  winnerNomination6: '6-ое место',
  winnerNomination7: '7-ое место',
  winnerNomination8: '8-ое место',
  winnerNomination9: '9-ое место',
  winnerNomination10: '10-ое место',
  winnerNomination11: '11-ое место',
  winnerNomination12: '12-ое место',
  winnerNomination13: '13-ое место',
  winnerNomination14: '14-ое место',
  winnerNomination15: '15-ое место',
  winnerNominationElement: 'Моя стихия - ТС',
  winnerElementTitle: 'Победитель конкурса "Моя стихия - ТС"!',
  winnerElementText: 'Точные прогнозы и вера в Баварию с Ливерпулем принесли Сергею почетное место в Зале Славы. Теперь его имя золотыми буквами вписано в историю SageSports 🌟',
  winnerElementFooter: 'Почетное место в зале славы',
  winnerGroupPrize1: '2500 USD',
  winnerGroupPrize2: '1500 USD',
  winnerGroupPrize3: '1000 USD',
  winnerGroupPrizeThunderstorm: 'Оригинальная футболка любимого клуба',
  winnerGroupPrizeLoser: 'Секретный приз',
  winnerPrize1: 'MSI Katana 15 Gaming Laptop',
  winnerPrize2: 'IPhone 15 Pro Max',
  winnerPrize3: 'Apple 12.9" iPad Pro M2 Chip',
  winnerPrize4: 'Steam Deck 1TB OLED',
  winnerPrize5: 'Steam Deck 1TB OLED',
  winnerPrize6: 'Apple AirPods Max',
  winnerPrize7: 'Apple AirPods Max',
  winnerPrize8: 'Apple Watch Series 9',
  winnerPrize9: 'Apple Watch Series 9',
  winnerPrize10: 'Подарочная карта Nike',
  winnerPrize11: 'Подарочная карта Nike',
  winnerPrize12: 'Годовая подписка на спортивные трансляции',
  winnerPrize13: 'Годовая подписка на спортивные трансляции',
  winnerPrize14: 'Годовая подписка на YouTube Premium',
  winnerPrize15: 'Годовая подписка на YouTube Premium',
  winnerPrizeEU24_tournament_winner: 'MacBook Pro 14" M3',
  winnerPrizeEU24_group_winner_phone: 'Смартфон Apple iPhone 15 256GB',
  winnerPrizeEU24_team_winner_card: 'Подарочная карта Nike/Adidas/Puma/Reebok',
  winnerPrizeEU24_team_winner_airpods: 'Наушники Apple AirPods Pro 2nd Gen',
  winnerPrizeEU24_team_winner_watch: 'Смарт-часы HUAWEI GT 3',
  winnerPrizeEU24_group_winner_shirt: 'Джерси победителя ЕВРО-2024',
  winnerEU24: 'Победитель турнира',
  winnerEU24Group: 'Победитель в группе №',
  winnerInTeam: 'Победители команд №',
  winnerEU24textwinner: 'Ты — настоящий футбольный гений! Так держать, чемпион!',
  winnerEU24textExperimenters:
    'Ты - словно виртуозный нападающий, который забивает решающий гол на последних минутах. Но VAR решил чуть иначе... “Серебро” твое! ',
  winnerEU24textTactics:
    'Ты доказал, что правильная стратегия всегда побеждает. Браво, тактик! Ждем в чате свежих предикшнов=)',
  winnerEU24textMotivators: 'Ты, как Джеррард и Тотти - мотивируешь всех двигаться вперед! ',
  winnerEU24textWizards:
    'Ты - наш футбольный волшебник, а твой результат — настоящее чудо! Но помни, колдовать за пределами SageSports - строго запрещается! ',
  winnerEU24textCruyff: 'Ты, как великий Йохан Кройф — образец для подражания!',
  winnerEU24textHiddink: 'Ты - наш новый Гус Хиддинк! Знаешь, как вывести СБОРНУЮ на новый уровень!',
  winnerEU24textMancini: 'Ты, как Роберто Манчини - сохраняешь элегантность и стиль во всем!',
  winnerEU24textDesham: 'Ты, как Дидье Дешам - можешь дойти до полуфинала без голов!',
  winnerEU24textGuttmann: 'Ты, как легендарный Гуттман - всегда знаешь, когда сделать решающий шаг!',
  winnerEU24textGuardiola: 'Ты, как Пеп “Лысый Шарлатан” Гвардиола - вдохновляешь на инновации и креативность!',
  winnerEU24textKlopp: 'Ты, как Юрген Клопп - умеешь завести команду и привести к успеху без лишних затрат!',
  winnerEU24textMourinho: 'Ты, как Жозе Моуриньо - всегда готов припарковать “автобус” для достижения результата!',
  winnerEU24textZidane: 'Ты, как Зинедин Зидан - всегда спокоен и уверен в своих силах! Так держать!',
  winnerEU24textLoev: 'Ты, как Йоахим Лёв - всегда находишь подход к любому сопернику!',
  winnerEU24textEmery:
    'Ты, как Унаи “пусть говорит наш тренеришка” Эмери - умеешь найти слабые места в защите соперника! Следующая остановка - Лига Европы!',
  winnerEU24textAncelotti: 'Ты, как Карло Анчелотти - затащил турнир с сигарой и поднятой бровью! Браво!',
  winnerEU24textMichels: 'Ты, как Ринус Михелс - всегда за творческий подход на поле! ',
  winnerEU24textFerguson: 'Ты, как сэр Алекc - зарешал в “Ферги-тайм” и сорвал овации! ',
  winnerEU24textTuchel:
    'Ты, как Томас Тухель - всегда находишь интересные решения, иногда даже через скандалы, но это не умаляет твоего профессионализма',
  winnerEU24textPaisley: 'Ты, как Боб Пейсли - всегда верен своей тактике и стилю! ',
  teamUsersList: 'Состав команды',
  pointsForGryffindor: '50 очков Гриффиндору',
  pointsForGryffindorTitle1: 'За что?',
  pointsForGryffindorTitle2: 'среди 20 команд',
  pointsForGryffindorTitle3: 'в конкурсе',
  pointsForGryffindorDescription1: 'Ребята подошли к этапу плей-офф ЕВРО-24',
  pointsForGryffindorDescription2: 'с наибольшим количеством очков среди всех участников',
  moreDetails: 'Подробнее',
  ufcGiftLabel1: 'Apple iPad Air 13',
  ufcGiftLabel2: 'PS5 Slim + игра UFC 5',
  ufcGiftLabel3: 'Steam Deck',
  ufcTableDate: 'Время',
  ufcTableFighters: 'Бойцы',
  ufcTableResult: 'Результаты',
  ufcTableBackup: 'Запасной бой',
  ufcTableMultiply: 'Удвоение кэфа',
  ufcPotentialCoef: 'Возможный выигрышный кэф',
  ufcSerialCoef: 'Кэф за серию турниров',
  ufcRoundCoef: 'Кэф за турнир',
  ufcSumCoef: 'Итоговый кэф',
  ufcTimerText: 'До возможности оставлять прогнозы осталось:',
  ufcWinnerPredictionManual1: 'В рамках каждого турнира необходимо оставить прогноз на <strong>5 боев и 1 запасной бой</strong>, на случай отмены одного из основных боев.',
  ufcWinnerPredictionManual2: 'Функции "Запасной бой" и "Удвоение кэфа" становятся доступны после выбора победителя.',
  generalFaqTitle: 'Список общих вопросов и ответов',
  disciplineFaqTitle: 'Список вопросов и ответов по дисциплине “{discipline}"',
  footballFaqTitle: 'Список вопросов и ответов по дисциплине “Футбол”',
  ufcFaqTitle: 'Список вопросов и ответов по дисциплине “UFC”',
  'Пара моментов...': 'Пара моментов...',
  'Время вышло': 'Время вышло',
  'Вы больше не можете оставлять прогнозы': 'Вы больше не можете оставлять прогнозы',
  'Номерной турнир пока не сформирован': 'Номерной турнир пока не сформирован',
  'Нет боев с прогнозами': 'Нет боев с прогнозами',
  'Необходимо поставить прогноз на 6 боев': 'Необходимо поставить прогноза на 6 боев',
  'Главный и со-главный бои обязательны к выбору и они не могут быть выбраны в качестве запасного боя': 'Главный и со-главный бои обязательны к выбору и они не могут быть выбраны в качестве запасного боя',
  'Запасной бой обязателен к выбору': 'Запасной бой обязателен к выбору',
  'Главный и соглавный бои не могут быть выбраны в качестве запосного': 'Главный и соглавный бои не могут быть выбраны в качестве запосного',
  'Только один бой может получить удвоение кэфа': 'Только один бой может получить удвоение кэфа',
  'Турнир завершён': 'Турнир завершён',
  'Возможность оставлять прогнозы на следующий турнир откроется за 5 дней до начала первого боя': 'Возможность оставлять прогнозы на следующий турнир откроется за 5 дней до начала первого боя',
  pluralPoints: '0 баллов | {n} балл | {n} балла | {n} баллов',
  'Список команд пока не сформирован': 'Список команд пока не сформирован',
  'Рейтинги и история прогнозов пользователей пока не доступны': 'Рейтинги и история прогнозов пользователей пока не доступны',
  'До закрытия возможности ставить и менять прогноз:': 'До закрытия возможности ставить и менять прогноз:',
};
