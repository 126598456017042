<script lang="ts" setup>
import {
  computed, onMounted, onUnmounted, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useTournamentStore } from '@/stores/tournament';
import { useResponsive } from '@/composables/useResponsive';
import AppHeader from '@/components/App/Header/AppHeader.vue';
import BaseTabs from '@/components/Base/Tabs/BaseTabs.vue';
import BaseSelect from '@/components/Base/Select/BaseSelect.vue';
import AppFooter from '@/components/App/Footer/AppFooter.vue';
import BaseMessage from '@/components/Base/Message/BaseMessage.vue';
import BaseFullScreenLoader from '@/components/Base/FullScreenLoader/BaseFullScreenLoader.vue';
import BaseButton from '@/components/Base/Button/BaseButton.vue';
import { Routes } from '@/types/routes';
import { TournamentTheme } from '@/types/tournaments';
import { Breakpoint } from '@/types/responsive';

interface RouteOption {
  label: string;
  slug: Routes;
  disabled?: boolean;
}

const { t } = useI18n();
const { currentBreakpoint } = useResponsive();
const route = useRoute();
const router = useRouter();

const store = useTournamentStore();

const isLoading = computed(() => store.isLoading);
const tournament = computed(() => store.data);
const theme = computed(() => store.data?.theme ?? 'main');

const slug = computed(() => String(route.params.slug));
const routeName = computed(() => String(route.name));

const routeOptions = computed<RouteOption[]>(() => [
  { label: t('predictions'), slug: Routes.Tournament },
  { label: t('ratings'), slug: Routes.TournamentRatings, disabled: !store.isRatingsAccess },
  { label: t('stats'), slug: Routes.TournamentStats },
  { label: t('rules'), slug: Routes.TournamentRules },
]);

const selectedRoute = computed(() => (
  routeOptions.value.find((option) => option.slug === routeName.value)
  || routeOptions.value[0]
));

const routeTo = (routeOption: RouteOption) => {
  switch (routeOption.slug) {
    case Routes.TournamentRatings:
      router.push({ name: Routes.TournamentRatings, params: { slug: slug.value } });
      break;
    case Routes.TournamentStats:
      router.push({ name: Routes.TournamentStats, params: { slug: slug.value } });
      break;
    case Routes.TournamentRules:
      router.push({ name: Routes.TournamentRules, params: { slug: slug.value } });
      break;
    default:
      router.push({ name: Routes.Tournament, params: { slug: slug.value } });
  }
};

const fetchData = (tournamentSlug: string | undefined) => {
  if (tournamentSlug) {
    store.get(tournamentSlug);
  }
};

watch(slug, (newValue) => {
  store.$reset();
  fetchData(newValue);
});

onMounted(() => {
  fetchData(slug.value);
});

onUnmounted(() => {
  store.$reset();
});
</script>

<template>
  <div
    class="layout"
    :class="{
      'layout--path-of-sage-theme': theme === TournamentTheme.PathOfSage,
      'layout--football-elements-theme': theme === TournamentTheme.FootballElements,
      'layout--ufc-theme': theme === TournamentTheme.UFC,
    }"
  >
    <BaseFullScreenLoader v-if="isLoading"/>

    <template v-else>
      <AppHeader
        class="layout__header"
        :theme="theme"
        is-sport
      />

      <div class="layout__nav container">
        <BaseSelect
          v-if="currentBreakpoint === Breakpoint.Mobile"
          :selected-option="selectedRoute"
          :options="routeOptions"
          :theme="theme === TournamentTheme.UFC ? TournamentTheme.UFC : 'tournament'"
          style="width: 100%;"
          @select="routeTo"
        />

        <BaseTabs
          v-else
          :current-tab="selectedRoute"
          :tabs="routeOptions"
          :theme="theme"
          @select="routeTo"
        />
      </div>

      <main
        class="layout__content"
        :class="{
        'layout__content--path-of-sage-theme': theme === TournamentTheme.PathOfSage,
        'layout__content--football-elements-theme': theme === TournamentTheme.FootballElements,
        'layout__content--ufc': theme === TournamentTheme.UFC,
      }"
      >
        <slot v-if="tournament" />

        <div
          v-else
          class="container layout__error-section"
        >
          <BaseMessage
            class="feed__message"
            severity="error"
            :text="t('tournamentErrorText')"
          />

          <BaseButton
            :label="t('goHome')"
            :to="{name: Routes.Home}"
          />
        </div>
      </main>

      <AppFooter
        :sport-slug="tournament?.sport.slug"
        :theme="theme || 'main'"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include md {
    background-size: 200% auto;
  }

  @include lg {
    background-size: 100% auto;
  }

  &--path-of-sage-theme {
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 300% auto;
    background-color: $lilac;
    background-image: url('@/assets/image/bg/sage-pattern.png');
  }

  &--ufc-theme {
    background-color: $ufc-dark-bg;
  }
}

.layout__header {
  z-index: $z-header;
  position: fixed;
  top: 0;
  left: 0;
}

.layout__nav {
  z-index: $z-level-3;
  position: fixed;
  width: 100%;
  top: calc($header-h-mobile + 4px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  @include lg {
    width: auto;
    padding: 0;
  }
}

.layout__content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $tournament-header-h;
  padding-bottom: 40px;

  &--football-elements-theme{
    background-color: $fe-bg;
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100% auto;
    background-image: url('/image/bg/football-elements/pattern-414.svg');

    @include md {
      background-image: url('/image/bg/football-elements/pattern-768.svg');
    }

    @include lg {
      background-image: url('/image/bg/football-elements/pattern-1366.svg');
    }
  }

  &--ufc{
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% auto;
    background-image: url('/image/bg/ufc/pattern--mobile.svg');

    @include md {
      background-image: url('/image/bg/ufc/pattern--tablet.svg');
    }

    @include lg {
      background-repeat: no-repeat, repeat-y, repeat-y;
      background-position: 0 100%, 0 0, 100% 0;
      background-size: 100% auto, auto, auto;
      background-image:
        url('/image/bg/ufc/pattern--desktop.svg'),
        url('/image/bg/ufc/pattern-left.svg'),
        url('/image/bg/ufc/pattern-right.svg');
    }
  }
}

.layout__loader {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout__error-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
</style>
