<script lang="ts" setup>
import {
  computed, onBeforeMount, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useSportsStore } from '@/stores/sportStore';
import AppHeader from '@/components/App/Header/AppHeader.vue';
import AppFooter from '@/components/App/Footer/AppFooter.vue';
import SportsFeed from '@/components/Sports/Feed/SportsFeed.vue';
import BaseFullScreenLoader from '@/components/Base/FullScreenLoader/BaseFullScreenLoader.vue';
import BaseMessage from '@/components/Base/Message/BaseMessage.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const toast = useToast();

const sportsStore = useSportsStore();

const sportSlug = computed(() => String(route.query.sport));
const hasSportQueryParams = computed(() => !!route.query.sport);

const setDefaultSportSlug = () => {
  router.replace({ query: { ...route.query, sport: 'football' } });
};

const checkSportSlug = (slug: string) => {
  if (sportsStore.slugToSport[slug] === undefined) {
    setDefaultSportSlug();
  }
};

const fetchData = async () => {
  if (!sportsStore.data) {
    await sportsStore.get()
      .then(() => {
        checkSportSlug(sportSlug.value);
      })
      .catch(() => {
        toast.add({
          severity: 'error',
          summary: t('defaultErrorText'),
          life: 3000,
        });
      });
  }
};

watch(sportSlug, (newValue) => {
  checkSportSlug(newValue);
});

onMounted(() => {
  fetchData();
});

onBeforeMount(() => {
  setDefaultSportSlug();
});
</script>

<template>
  <div class="layout">
    <AppHeader
      class="layout__header"
      theme="main"
      :is-sport="hasSportQueryParams"
    />

    <main class="layout__content">
      <BaseFullScreenLoader v-if="sportsStore.isLoading"/>

      <template v-else-if="sportsStore.data">
        <SportsFeed
          v-if="!hasSportQueryParams"
          :sports="sportsStore.data"
        />

        <slot v-else />
      </template>

      <BaseMessage
        v-else
        severity="error"
        :text="t('tournamentErrorText')"
      />
    </main>

    <AppFooter
      :sport-slug="sportSlug"
      theme="main"
    />
  </div>
</template>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: $black;
  overflow-y: auto;
  background-repeat: repeat, no-repeat;
  background-position: 0 0, 0 0;
  background-size: auto, cover;
  background-image:
    url('@/assets/image/bg/home-pattern@1x.png'),
    $bg-gradient;

  @include retina {
    background-image:
      url('@/assets/image/bg/home-pattern@2x.png'),
      $bg-gradient;
  }

  @include lg {
    background-repeat: repeat, no-repeat;
    background-position: 0 0, 0 0, 0 0;
    background-size: 100% auto, auto, cover;
    background-image:
      url('@/assets/image/bg/home-bg--desktop@1x.png'),
      url('@/assets/image/bg/home-pattern@1x.png'),
      $bg-gradient;

    @include retina {
      background-image:
        url('@/assets/image/bg/home-bg--desktop@2x.png'),
        url('@/assets/image/bg/home-pattern@1x.png'),
        $bg-gradient;
    }
  }
}

.layout__header {
  z-index: $z-header;
  position: fixed;
  top: 0;
  left: 0;
}

.layout__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: $header-h-mobile;

  @include lg {
    padding-top: $header-h-desktop;
  }
}
</style>
