import { TournamentTheme as Theme } from '@/types/tournaments';
import { TournamentSystemName as SystemName } from '@/constants/tournaments.const';

const themeConfig = {
  [Theme.PathOfSage]: [SystemName.Euro24 as string],
  [Theme.UFC]: [SystemName.Ufc as string],
  [Theme.FootballElements]: [
    SystemName.UefaCL2425Group as string,
    SystemName.UefaCL2425PlayOff as string,
  ],
};

const logosConfig = {
  [SystemName.UefaCL2425PlayOff]: {
    officialLogoSrc: '/image/logo/champions-league.svg',
    themeLogoSrc: '/image/logo/gods-of-football.svg',
  },
  [SystemName.UefaCL2425Group]: {
    officialLogoSrc: '/image/logo/champions-league.svg',
    themeLogoSrc: '/image/logo/football-elements.svg',
  },
  [SystemName.Euro24]: {
    officialLogoSrc: '/image/logo/euro-2024.svg',
    themeLogoSrc: '/image/logo/path-of-sage.svg',
  },
  [SystemName.Ufc]: {
    officialLogoSrc: '/image/logo/ufc.svg',
    themeLogoSrc: null,
  },
};

export const setTournamentTheme = (systemName: string | SystemName): Theme => {
  const themes = Object.keys(themeConfig) as Theme[];
  const foundTheme = themes.find((theme) => themeConfig[theme].includes(systemName));
  return foundTheme ?? Theme.PathOfSage;
};

export const setTournamentLogos = (systemName: SystemName | string): {
  officialLogoSrc: string | null,
  themeLogoSrc: string | null,
} => {
  const isTournamentSystemName = Object.values(SystemName).includes(systemName as SystemName);

  if (isTournamentSystemName) {
    return logosConfig[systemName as SystemName];
  }

  return {
    officialLogoSrc: null,
    themeLogoSrc: null,
  };
};
