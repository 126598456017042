<script setup lang="ts">
import { computed, resolveComponent } from 'vue';
import AppIcon from '@/components/App/Icon/AppIcon.vue';
import type { RouteLocationRaw } from 'vue-router';

const props = withDefaults(defineProps<{
  label: string;
  appearance?: 'primary'
  | 'secondary'
  | 'negative'
  | 'pas-primary'
  | 'pas-secondary'
  | 'fe-primary'
  | 'fe-secondary'
  | 'ufc-primary';
  fitWidth?: boolean;
  size?: 's' | 'm' | 'l';
  isDisabled?: boolean;
  isLoading?: boolean;
  to?: string | RouteLocationRaw;
}>(), {
  appearance: 'primary',
  size: 'm',
});

const isExternalLink = computed(() => (
  typeof props.to === 'string' && props.to.startsWith('http')
));

const buttonClasses = computed(() => ({
  'base-button--size-s': props.size === 's',
  'base-button--size-m': props.size === 'm',
  'base-button--size-l': props.size === 'l',

  'base-button--fit-width': props.fitWidth,

  'base-button--is-loading': props.isLoading,

  'base-button--primary': props.appearance === 'primary',
  'base-button--secondary': props.appearance === 'secondary',
  'base-button--negative': props.appearance === 'negative',

  'base-button--pas-primary': props.appearance === 'pas-primary',
  'base-button--pas-secondary': props.appearance === 'pas-secondary',

  'base-button--fe-primary': props.appearance === 'fe-primary',
  'base-button--fe-secondary': props.appearance === 'fe-secondary',

  'base-button--ufc-primary': props.appearance === 'ufc-primary',
}));
</script>

<template>
  <a
    v-if="isExternalLink"
    :href="String(props.to)"
    target="_blank"
    class="base-button title-16-medium"
    :class="buttonClasses"
  >
    <AppIcon
      v-if="props.isLoading"
      class="base-button__spin"
      name="spin"
    />

    <span class="base-button__label">
      {{props.label}}
    </span>
  </a>

  <component
    v-else
    :is="props.to ? resolveComponent('router-link') : 'button'"
    :to="props.to ?? undefined"
    class="base-button"
    :class="buttonClasses"
    :disabled="props.isDisabled || props.isLoading || undefined"
  >
    <AppIcon
      v-if="props.isLoading"
      class="base-button__spin"
      name="spin"
    />

    <span class="base-button__label">
      {{props.label}}
    </span>
  </component>
</template>

<style lang="scss" scoped>
.base-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 4px;
  cursor: pointer;
  overflow: hidden;
  padding: 14px 16px;
  flex-shrink: 0;
  font-family: Unbounded, sans-serif;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.03em;

  &--size-s {
    min-width: 200px;
    padding: 11px 16px;
    font-size: 14px;
  }

  &--size-m {
    min-width: 200px;
    padding: 14px 16px;
    font-size: 16px;
  }

  &--fit-width {
    min-width: fit-content;
  }

  &--primary {
    color: $clear-100;
    background: $gradient;
    outline-color: transparent;
    border: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;

    &:not(:disabled) {
      &:hover {
        background: $gradient-light;
      }

      &:active {
        background: $gradient-pressed;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &--secondary {
    color: $clear-100;
    background: $blue-5;
    border-radius: 8px;
    backdrop-filter: blur(12px);
    outline-color: transparent;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s,
      outline-color 0.2s;

    &::before {
      content: "";
      position: absolute;
      inset: 1px;
      border-radius: 8px;
      padding: 1px;
      background: $gradient-20;
      mask: linear-gradient($clear-100 0 0) content-box, linear-gradient($clear-100 0 0);
      mask-composite: exclude;
      pointer-events: none;
      transition: all 0.2s;
    }

    &:not(:disabled) {
      &:hover {
        background: $gradient-20;

        &::before {
          background: $gradient;
        }
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &--negative {
    background: transparent;
    border: none;
    border-radius: 4px;
    color: $error-100;

    &:not(:disabled) {
      &:hover {
        border-color: transparent;
        background: $error-10;
        color: $error-100;
      }

      &:active {
        background: $error-100;
        color: $clear-100;
      }
    }
  }

  &--pas-primary {
    border: 1px solid transparent;
    background: $yellow-100;
    color: $lilac-150;
    letter-spacing: 0.03em;
    transition: all 0.2s;

    &:not(:disabled) {
      &:hover {
        border: 1px solid $yellow-100;
        background: transparent;
        color: $clear-100;
      }

      &:active {
        background: $yellow-120;
      }
    }
  }

  &--pas-secondary {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $yellow-100;

    &:not(:disabled) {
      &:hover {
        border-color: transparent;
        background: $yellow-100;
        color: $lilac-150;
      }

      &:active {
        background: $clear-100;
        color: $lilac-150;
      }
    }

    &:disabled {
      border-radius: 4px;
      background-color: var(--Football-Elements-Clear-5, rgba(255, 255, 255, 0.05));
      border-color: transparent;
      color: $clear-30;
    }
  }

  &--fe-primary {
    border-radius: 4px;
    background-color: $yellow-100-fe;
    border: 1px solid $yellow-100-fe;
    color: $fe-bg;
    transition: color $transition, background $transition, border $transition;
    font-family: $font-family-second;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.03em;

    &:not(:disabled) {
      &:hover {
        color: $fe-bg;
        background-color: $clear-100;
        border: 1px solid $clear-100;
      }

      &:active {
        background-color: $yellow-120-fe;
        color: $fe-bg;
        border: 1px solid $yellow-120-fe;
      }
    }

    &:disabled {
      background-color: var(--Football-Elements-Clear-5, rgba(255, 255, 255, 0.05));
      border-color: transparent;
      color: $clear-30;
    }
  }

  &--fe-secondary {
    border-radius: 4px;
    padding: 12px;
    background-color: transparent;
    border: 1px solid rgba($color: $yellow-100-fe, $alpha: 0.3);
    min-width: 200px;
    color: $yellow-100-fe;
    transition: color $transition, background $transition, border $transition;
    font-family: $font-family-second;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.03em;

    &:not(:disabled) {
      &:hover {
        color: $clear-100;
        background-color: $clear-20;
        border-color: $clear-90;
      }

      &:active {
        background-color: transparent;
        color: $yellow-100-fe;
        border-color: rgba($color: $yellow-100-fe, $alpha: 0.3);
      }
    }

    &:disabled {
      background-color: transparent;
      border-color: $clear-20;
      color: $clear-30;
    }
  }

  &--ufc-primary {
    padding: 12px;
    min-width: 180px;
    color: $clear-100;
    font-family: $font-family-second;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.03em;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='48' viewBox='0 0 180 48' fill='none'%3E%3Cpath d='M1 33.5858V14.4142L14.4142 1H165.586L179 14.4142V33.5858L165.586 47H14.4142L1 33.5858Z' stroke='%23E65700' stroke-width='2'/%3E%3C/svg%3E");

    &:not(:disabled) {
      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='48' viewBox='0 0 180 48' fill='none'%3E%3Cpath d='M1 33.5858V14.4142L14.4142 1H165.586L179 14.4142V33.5858L165.586 47H14.4142L1 33.5858Z' fill='%23E65700' fill-opacity='0.2' stroke='%23E65700' stroke-width='2'/%3E%3C/svg%3E");
      }

      &:active {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='48' viewBox='0 0 180 48' fill='none'%3E%3Cpath d='M0 14L14 0H166L180 14V34L166 48H14L0 34V14Z' fill='%23E65700'/%3E%3C/svg%3E");
      }
    }

    &:disabled {
      color: $ufc-primary-20;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='48' viewBox='0 0 180 48' fill='none'%3E%3Cpath d='M1 33.5858V14.4142L14.4142 1H165.586L179 14.4142V33.5858L165.586 47H14.4142L1 33.5858Z' stroke='%23191919' stroke-width='2'/%3E%3C/svg%3E");    }
  }

  &--is-loading {
    .base-button__spin {
      -webkit-animation: icon-spin 2s infinite linear;
      animation: icon-spin 2s infinite linear;
    }
  }
}

.base-button__label {
  flex: 1 1 auto;
  text-align: center;
}

.base-button__spin {
  width: 14px;
  height: 14px;
}
</style>
