/* eslint-disable vue/max-len */
/* eslint-disable max-len */
export default {
  predictionStep1Text: 'Prediction not been set',
  predictionStep2Text: 'You didn\'t save prediction',
  predictionStep3Text: 'Prediction was logged successfully!',
  predictionStep4Text: 'Do you want to change prediction?',
  prizes: 'Prizes',
  createTeamButtonText: 'Create a friends team!',
  accountNewsletter: 'Subscribe to newsletter in Reddy',
  invalidReddyCode: 'The code was entered incorrectly. Check the code in Reddy and enter again.',
  groupsCardButton: 'Teams',
  groupsCardButtonActive: 'Close',
  groupsCardScoresLabel: 'All scores',
  topGoalscorer: 'Top goalscorer',
  topGoalkeeper: 'Top goalkeeper',
  top: 'Top',
  goalscorer: 'goalscorer',
  goalkeeper: 'goalkeeper',
  chooseFavorite: 'Choose your favorite!',
  notChooseFavorite: 'Favorite is not chose',
  playerSearchPlaceholder: 'First and last player name...',
  userSearchPlaceholder: 'First and last participant name ...',
  dateAndTime: 'Date and time',
  teams: 'Teams',
  score: 'Score',
  yourResult: 'Your result',
  tournamentsFeedErrorText: 'The feed of tournaments could not be loaded',
  tournamentsFeedEmptyText: 'There are no tournaments available',
  tournamentErrorText: 'Failed to upload tournament data',
  tournamentsTypeFootball: 'Football',
  tournamentsTypeUFC: 'UFC',
  tournamentsTabText1: 'All',
  tournamentsTabText2: 'Current',
  tournamentsTabText3: 'Past',
  playerTableEmptyText: 'Player is not found, try changing the search params',
  ratingTablePosition: 'Ranking',
  ratingTableUser: 'Player',
  ratingTableTeam: 'Team',
  ratingTablePoints: 'Points',
  ratingTableCoefSum: 'Coefficient Sum',
  ratingTableFilterSelect: 'Overall rating',
  createUserTeamFormTitle: 'Your new team',
  createUserTeamInputPlaceholder: 'Enter team name',
  createUserTeamFormSubmit: 'To victory!',
  shareInviteText: 'Send an invite link to your friends!',
  confirmDeleteTeamText: 'Delete team',
  confirmExitTeamText: 'Do you want to leave this team?',
  alreadyInTeamText: 'You’re already in this team',
  joinErrorTeamText: 'Error joining team',
  inviteToTeamText: 'Want to join the team?',
  teamsLimitText: 'You can create no more than three teams with friends.',
  teamNameTaken: 'This team name already exists, please choose another one',
  teamsInvalidName: 'Use the following characters: numbers, Latin and Cyrillic letters, hyphen and underscore',
  teamsInvalidLengthName: 'Title length exceeded. Write the name up to 30 characters',
  teamPredictionTitle: 'Choose 1 team',
  'Команда выбрана': 'Prediction saved',
  waitingResults: 'Waiting results',
  results: 'Results',
  teamCounter: 'teams selected',
  teamCounterFormat: '{current}/{max} {teamCounter}',
  ctaRandom: 'May fortune be on your side!',
  rankingInTeam: 'Your ranking in the team',
  participantsInTeam: 'Participants in the team',
  teamRankingInTheGroup: 'Your team ranking in the group',
  placeGroup: 'Your place in the group',
  successPredictionText: 'Your prediction was logged successfully!',
  scoreLimitErrorText: 'Limit of goals per team is 20',
  groupsTableCol1: 'Your prediction',
  groupsTableCol2: 'Qualify teams',
  groupsTableCol3: 'Results',
  timerDayLabel: 'days',
  timerHoursLabel: 'hours',
  timerMinutesLabel: 'min.',
  timerSecondsLabel: 'sec.',
  myTeam: 'My team ({team})',
  friendsTeam: 'Friends team',
  allGroups: 'All groups',
  allTeams: 'All teams',
  faqTitle: 'Another question',
  faqContentTitle: 'Didn\'t find the answer to your question?',
  faqContentBody: 'Write it to our Reddy support Sage Sports',
  footer: 'Discuss forecasts in our Reddy group',
  hallOfFlameInDevelopment: 'The Hall of Fame will be available later...',
  limitFavoriteRatings: 'You cannot assign more than 5 users',
  loginFormTitle: 'Enter your Reddy account number',
  otpFormTitle: 'Enter the verification code sent to Reddy chat',
  reddyIdNotFound: 'The number does not exist. Check your Reddy number and enter again.',
  winnerGroupText1:
    '- First place is already yours! But who are you without him?\n- Genius, mathematician, analyst, winner in life!',
  winnerGroupText2:
    'Keep it up! The Champions League is your element! Come back and bring a new tour, like Bayer in the Bundesliga!',
  winnerGroupText3: 'Yes, you are in the TOP 3!\nGain strength and keep up the good work!',
  winnerGroupTextThunderstorm: 'You\'re on a roll, people might say that you\'ve taken the bookies to the cleaners! Put that winning streak to good use at our casino!',
  winnerGroupTextLoser: 'If it\'s any consolation, you\'re "first" from the end!\nAnd if not, then you know, bookmakers\nthe offices love you and are waiting for you.',
  winnerText1: 'Our number one!\nNo, not the goalkeeper, you finished top!',
  winnerText2: 'So close to victory!\nBut… a card, a penalty and BANG… you’ve got a silver medal around your neck!',
  winnerText3: 'You’re like Atlético Madrid II\nOnly the cream of the crop finished higher!',
  winnerText4: '4th place?\nIt\'s as easy as 1-2-3…',
  winnerText5: 'You’re a home-grown prodigy!\nYou know the Champion’s League like the back of your hand!',
  winnerText6: 'You have a sixth sense:\nforget analysis, it’s all about following your gut instinct!',
  winnerText7: 'The real CR7!\nNo, not that phoney in Saudi Arabia, we have the one and only original right here!',
  winnerText8: 'The golden middle way!\nYou finished mid-table but it was enough in the end!',
  winnerText9: 'All your predictions came in… right in the top corner like a proper number 9!\nYou nearly broke the back of the net!',
  winnerText10: 'You’re no “false” number 10!\nNo one’s switching with you in this position!',
  winnerText11: 'Unchallenged and underappreciated – number 11!\nThe soon-to-be Drogba!',
  winnerText12: 'You\'re the hero on the bench!\nGet ready to be the first one subbed on!',
  winnerText13: 'You\'ve blown superstition out the water!\nWhat a nightmare for number 13!',
  winnerText14: 'You\'re like Cruyff and Henry\n– a legendary number 14!',
  winnerText15: 'You\'re cutting it fine!\nCongratulations, you\'re the last on the bus but you got there!',
  winnerNomination1: '1st place',
  winnerNomination2: '2nd place',
  winnerNomination3: '3rd place',
  winnerNomination4: '4th place',
  winnerNomination5: '5th place',
  winnerNomination6: '6th place',
  winnerNomination7: '7th place',
  winnerNomination8: '8th place',
  winnerNomination9: '9th place',
  winnerNomination10: '10th place',
  winnerNomination11: '11th place',
  winnerNomination12: '12th place',
  winnerNomination13: '13th place',
  winnerNomination14: '14th place',
  winnerNomination15: '15th place',
  winnerNominationElement: 'My Element is AS',
  winnerElementTitle: 'The winner of the “My Element - TS” contest!',
  winnerElementText: 'Accurate predictions and faith in Bayern and Liverpool earned Sergey an honorable place in the Hall of Fame. Now his name is written in golden letters in the history of SageSports 🌟',
  winnerElementFooter: 'An honorable place in the Hall of Fame',
  winnerGroupPrize1: '2500 USD',
  winnerGroupPrize2: '1500 USD',
  winnerGroupPrize3: '1000 USD',
  winnerGroupPrizeThunderstorm: 'Original T-shirt of your favorite club',
  winnerGroupPrizeLoser: 'Secret prize',
  winnerPrize1: 'MSI Katana 15 Gaming Laptop',
  winnerPrize2: 'IPhone 15 Pro Max',
  winnerPrize3: 'Apple 12.9" iPad Pro M2 Chip',
  winnerPrize4: 'Steam Deck 1TB OLED',
  winnerPrize5: 'Steam Deck 1TB OLED',
  winnerPrize6: 'Apple AirPods Max',
  winnerPrize7: 'Apple AirPods Max',
  winnerPrize8: 'Apple Watch Series 9',
  winnerPrize9: 'Apple Watch Series 9',
  winnerPrize10: 'Nike Gift Card',
  winnerPrize11: 'Nike Gift Card',
  winnerPrize12: 'Annual subscription to sports broadcasts',
  winnerPrize13: 'Annual subscription to sports broadcasts',
  winnerPrize14: 'YouTube Premium Annual Subscription',
  winnerPrize15: 'YouTube Premium Annual Subscription',
  winnerPrize16: 'YouTube Premium Annual Subscription',
  winnerPrizeEU24_tournament_winner: 'MacBook Pro 14" M3',
  winnerPrizeEU24_group_winner_phone: 'Apple iPhone 15 256GB',
  winnerPrizeEU24_team_winner_card: 'Gift Card Nike/Adidas/Puma/Reebok',
  winnerPrizeEU24_team_winner_airpods: 'Apple AirPods Pro 2nd Gen',
  winnerPrizeEU24_team_winner_watch: 'Smart Watch HUAWEI GT 3',
  winnerPrizeEU24_group_winner_shirt: 'The winner\'s jersey EURO-2024',
  winnerEU24: 'Tournament winner',
  winnerEU24Group: 'Winner in group #',
  winnerInTeam: 'Winners of teams #',
  winnerEU24textwinner: 'You\'re a real football genius! Keep it up, champ!',
  winnerEU24textExperimenters:
    'You\'re like the virtuoso striker who scores the decisive goal in the final minute, only for VAR to have other ideas... The silver is yours!',
  winnerEU24textTactics: 'You\'re proof that the right strategy always wins. Bravo, tactician! We look forward to fresh predictions in the chat =)',
  winnerEU24textMotivators: 'Like Gerrard and Totti, you motivate everyone to move forward!',
  winnerEU24textWizards: 'You\'re our football wizard, and your results are magic! But remember, it\'s strictly forbidden to use your spells outside SageSports.',
  winnerEU24textCruyff: 'Like the great Johan Cruyff, you\'re an inspiration to us all!',
  winnerEU24textHiddink: 'You\'re our new Guus Hiddink! You know how to take a NATIONAL TEAM to the next level!',
  winnerEU24textMancini: 'Like Roberto Mancini, you maintain elegance and style in everything you do!',
  winnerEU24textDesham: 'Like Didier Deschamps, you can reach the semifinals without scoring a single goal!',
  winnerEU24textGuttmann: 'Like the legendary Béla Guttman, you always know when to take the plunge!',
  winnerEU24textGuardiola: 'Like Pep “The Bald Fraud” Guardiola, you inspire innovation and creativity!',
  winnerEU24textKlopp: 'Like Jürgen Klopp, you know how to build a team and lead it to success at the minimum expense!',
  winnerEU24textMourinho: 'Like Jose Mourinho, you\'re always ready to park the bus to achieve results!',
  winnerEU24textZidane: 'Like Zinedine Zidane, you\'re always calm and confident in your abilities. Keep going!',
  winnerEU24textLoev: 'Like Joachim Löw, you always find the answer to any opponent!',
  winnerEU24textEmery: 'Like Unai Emery, you know how to spot the weaknesses in your opponent\'s defense. Next stop: Europa League!',
  winnerEU24textAncelotti: 'Like Carlo Ancelotti, you swept up at the tournament with a cigar and a raised eyebrow. Bravo!',
  winnerEU24textMichels: 'Like Rinus Michels, you\'re always up for getting creative on the pitch!',
  winnerEU24textFerguson: 'Like Sir Alex, you wrapped things up in Fergie time and earned a standing ovation!',
  winnerEU24textTuchel: 'Like Thomas Tuchel, you always find interesting solutions that may at times cause a furore, but never detract from your professionalism!',
  winnerEU24textPaisley: 'Like Bob Paisley, you stay true to your own tactics and style!',
  teamUsersList: 'Team members',
  pointsForGryffindor: '50 points for Gryffindor',
  pointsForGryffindorTitle1: 'For what?',
  pointsForGryffindorTitle2: 'among the 20 teams',
  pointsForGryffindorTitle3: 'in the competition',
  pointsForGryffindorDescription1: 'They have reached the stage of the EURO-24 playoffs',
  pointsForGryffindorDescription2: 'with the highest points among all participants',
  moreDetails: 'More details',
  ufcGiftLabel1: 'Apple iPad Air 13',
  ufcGiftLabel2: 'PS5 Slim + game UFC 5',
  ufcGiftLabel3: 'Steam Deck',
  ufcTableDate: 'Time',
  ufcTableFighters: 'Fighters',
  ufcTableResult: 'Results',
  ufcTableBackup: 'Reserve fight',
  ufcTableMultiply: 'Double coef',
  ufcPotentialCoef: 'Potential coefficient',
  ufcSerialCoef: 'Coef for a series of tournaments',
  ufcRoundCoef: 'Round coefficient',
  ufcSumCoef: 'Sum coefficient',
  ufcTimerText: 'Still time to leave predictions:',
  ufcWinnerPredictionManual1: 'For each event, you need to make predictions for <strong>5 fights and 1 reserve fight</strong> in case one of the main fights is canceled.',
  ufcWinnerPredictionManual2: "The 'Reserve Fight' and 'Double coef' features become available after selecting a winner.",
  generalFaqTitle: 'General questions and answers',
  disciplineFaqTitle: 'Questions and answers on the discipline “{discipline}"',
  footballFaqTitle: 'Questions and answers on the discipline “Football"',
  ufcFaqTitle: 'Questions and answers on the discipline “UFC"',
  'Пара моментов...': 'A little mistake...',
  'Время вышло': 'Time\'s up',
  'Вы больше не можете оставлять прогнозы': 'You can no longer set predictions',
  'Номерной турнир пока не сформирован': 'The numbered tournament has not been formed yet',
  'Нет боев с прогнозами': 'There are no fights with predictions',
  'Необходимо поставить прогноз на 6 боев': 'Require to set a prediction for 6 fights',
  'Главный и со-главный бои обязательны к выбору и они не могут быть выбраны в качестве запасного боя': 'The main and co-main fights are mandatory to choose and they cannot be chosen as a reserve fight',
  'Запасной бой обязателен к выбору': 'The reserve fight is mandatory to choose',
  'Главный и соглавный бои не могут быть выбраны в качестве запосного': 'The main and co-main fights cannot be chosen as a reserve fight.',
  'Только один бой может получить удвоение кэфа': 'Only one fight can get double coef.',
  'Турнир завершён': 'The tournament is finished',
  'Возможность оставлять прогнозы на следующий турнир откроется за 5 дней до начала первого боя': 'Access to set predictions for the next tournament will open 5 days before the start of the first fight',
  pluralPoints: '0 points | {n} point | {n} points',
  'Список команд пока не сформирован': 'Empty teams list',
  'Рейтинги и история прогнозов пользователей пока не доступны': 'User ratings and predictions history are not available yet.',
  'До закрытия возможности ставить и менять прогноз:': 'Time until the opportunity to make a prediction is closed:',
};
